.ltr {
    text-align: left;
}

.rtl {
    text-align: right;
}

.editor-container {
    margin: 20px auto 20px auto;
    border-radius: 2px;
    max-width: 1200px;
    color: #000;
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.editor-input {
    min-height: 150px;
    resize: none;
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.editor-input.dark-editor-content {
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-top: none;
}

.editor-text-bold {
    font-weight: bold;
}

.editor-text-italic {
    font-style: italic;
}

.editor-text-underline {
    text-decoration: underline;
}

.editor-text-strikethrough {
    text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
}

.editor-text-code {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
}

.editor-link, .editor-content a {
    color: #556cd6;
    text-decoration: inherit;
    &:hover {
        text-decoration: underline;
    }
}

.editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
}

.editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
}
.editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
    color: slategray;
}

.editor-tokenPunctuation {
    color: #999;
}

.editor-tokenProperty {
    color: #905;
}

.editor-tokenSelector {
    color: #690;
}

.editor-tokenOperator {
    color: #9a6e3a;
}

.editor-tokenAttr {
    color: #07a;
}

.editor-tokenVariable {
    color: #e90;
}

.editor-scroller {
    min-height: 150px;
    border: 0;
    display: flex;
    position: relative;
    outline: 0;
    z-index: 0;
    overflow: auto;
    max-height: 80vh;
}

.editor {
    flex: auto;
    position: relative;
    resize: vertical;
    z-index: -1;
}

.editor-tokenFunction {
    color: #dd4a68;
}

.editor-paragraph {
    margin: 0;
    position: relative;
}

.editor-paragraph:last-child {
    margin-bottom: 0;
}

.editor-heading-h1, .editor-content h1 {
    font-size: 24px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
}

.dark-editor-content .editor-heading-h1, .dark-editor-content h1 {
    color: rgb(250, 250, 250);
}

.editor-heading-h2, .editor-content h2 {
    font-size: 15px;
    color: rgb(101, 103, 107);
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    text-transform: uppercase;
}

.dark-editor-content .editor-heading-h2, .dark-editor-content h2 {
    color: rgb(225, 225, 225);
}

.editor-content img {
    max-width: 100%;
}

.editor-content p {
    /*margin-block-start: 0.5em;*/
    margin-block-end: 0.5em;
}


.editor-quote, .editor-content blockquote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}

.dark-editor-content .editor-quote, .dark-editor-content blockquote {
    color: rgb(184, 186, 190);
    border-left-color: rgb(206, 208, 212);
}

.editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.editor-listitem {
    margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
    list-style-type: none;
}