.custom-sidebar .Mui-selected:not(.custom-css-group){
    border-right: 2px solid #4695eb;
}

.custom-sidebar.mode-dark .Mui-selected{
    background-color:inherit;
}

.custom-css-group.Mui-selected{
    background-color:inherit!important;
}
.custom-css-group.Mui-selected, .custom-css-group.Mui-selected .custom-css-icon{
    color: #4695eb!important;
}

.custom-sidebar.mode-dark .custom-css-group.Mui-selected:hover{
    background-color: rgba(255, 255, 255, 0.08)!important;
}

.custom-sidebar .custom-css-group.Mui-selected:hover{
    background-color: rgba(0, 0, 0, 0.04)!important;
}

.custom-sidebar.mode-dark .Mui-selected:not(.custom-css-group){
    background-color: rgba(255, 255, 255, 0.16);
}

.custom-navbar.mode-light{
    background: #1d1d1d;
}

.custom-navbar .nav-button-row .MuiIconButton-root:hover{
    background: #68708454
}

/*.MuiListItemButton-root.has-child:has(> .Mui-selected){*/
/*    background:red;*/
/*}*/